<template>
  <div id="home">
    <!-- HERO BANNER SECTION -->
  </div>
</template>

<script src="https://fast.wistia.net/assets/external/E-v1.js" async></script>

<script>
import AOS from 'aos'
import 'aos/dist/aos.css'

import Vue from 'vue'
import VueRellax from 'vue-rellax'
Vue.use(VueRellax)

import rules from '@/rules'
import { mapState } from 'vuex'
import firebase from 'firebase/app'

export default {
  metaInfo: {
    title: 'Home',
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      m1Text: {},
      m2Text: {},
      m2List: {},
      m3Text: {},
      m3List: {},
      headline: {},
      copyright: {},
      homeVideo: {},
      m1Headline: {},
      m2Headline: {},
      m3Headline: {},
      subHeadline: {},
      bottomCards: {},
      bottomCtaText: {},
      bottomHeadline: {},
      bottomCtaTitle: {},
      inputBottomText: {},
      containerWidth: '1240',
    }
  },

  
  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: state => state.user.user,
      data: state => state.projects.data,
      status: state => state.projects.status,
    })
  },

  /*------------------------------------------------------------------------------
   * WATCH 
   *----------------------------------------------------------------------------*/
  watch: {
    'user': function (val) {
      if (val) {
        switch (val.role) { // default page after login
          case 'admin':
            this.$router.push({ name: 'OpenRequests' })
            break;
        
          default:
            this.$router.push({ name: 'AccountSettings' })
            break;
        }
      }
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    validateForm() {
      if (this.$refs.projectForm.validate()) {
        this.$store.dispatch('projects/create', )
      }
    },

    // GET REMOTE CONFIGS
    getConfigs() {
      let remoteConfig = firebase.remoteConfig()
      remoteConfig.settings.minimumFetchIntervalMillis = 15000
      
      remoteConfig.fetchAndActivate()
      .then(async () => {
        // HERO BANNER
        Promise.all([
          this.headline = remoteConfig.getValue('hero_headline'),
          this.subHeadline = remoteConfig.getValue('hero_sub_headline'),
          this.inputBottomText = remoteConfig.getValue('hero_input_bottom_text'),
          this.m1Headline = remoteConfig.getValue('m1_headline'),
          this.m1Text = remoteConfig.getValue('m1_text'),
          this.m2Headline = remoteConfig.getValue('m2_headline'),
          this.m2Text = remoteConfig.getValue('m2_text'),
          this.m2List = remoteConfig.getValue('m2_list'),
          this.m3Headline = remoteConfig.getValue('m3_headline'),
          this.m3Text = remoteConfig.getValue('m3_text'),
          this.m3List = remoteConfig.getValue('m3_list'),
          this.bottomHeadline = remoteConfig.getValue('bottom_headline'),
          this.bottomCards = remoteConfig.getValue('bottom_cards'),
          this.bottomCtaTitle = remoteConfig.getValue('bottom_cta_title'),
          this.bottomCtaText = remoteConfig.getValue('bottom_cta_text'),
          this.copyright = remoteConfig.getValue('copyright'),
          this.homeVideo = remoteConfig.getValue('home_video'),
        ])
        .then(() => {
          AOS.init()
        })
        
      })
      .catch(error => {
        console.log(error.message)
      })
    },
  },

  /*------------------------------------------------------------------------------
   * BEFORE CREATE
   *----------------------------------------------------------------------------*/
  beforeMount() {
    this.getConfigs()
  },

  /*------------------------------------------------------------------------------
   * CREATED
   *----------------------------------------------------------------------------*/
  created() {
    this.$store.commit('setViewState', 'view')

    if (this.$cookies.get('project') && !firebase.auth().currentUser) {
      this.$router.push({ name: 'Project', params: { id: this.$cookies.get('project') } })
    }
  }
}
</script>


<style lang="scss">
  @import '../sass/home.scss';
</style>